@font-face {
  font-family: "Oswald Bold";
  src: url("./assets/fonts/Oswald-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Oswald ExtraLight";
  src: url("./assets/fonts/Oswald-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Oswald Light";
  src: url("./assets/fonts/Oswald-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Oswald Medium";
  src: url("./assets/fonts/Oswald-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Oswald Regular";
  src: url("./assets/fonts/Oswald-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Oswald SemiBold";
  src: url("./assets/fonts/Oswald-SemiBold.ttf") format("truetype");
}
::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box;
}

header {
  font-size: 15px;
}

.footer {
  background: #000000;
  font-size: 13px;
}

/**********************
 **********************
 *                    *
 * GENERAL APP STYLES *
 *                    *
 **********************
 *********************/
html,
body,
#root {
  margin: 0;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

body {
  background-size: cover;
  background-attachment: fixed;
}
@media (max-width: 600px) {
  body {
    background-position: center;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 1px !important;
  line-height: 1 !important;
}

.section {
  /* position: relative; */
  display: flex;
  box-sizing: border-box;
  background-size: cover;
  /* position: static; */
  width: 100%;
  min-height: 100vh;
}
video {
  width: 100%;
}

.display.small {
  margin-bottom: -15px;
  margin-left: 6rem;
  text-transform: none;
}

.primary-menu a {
  padding: 0;
}
.primary-menu a:hover, .primary-menu a.active {
  background: none;
}
.primary-menu a:hover .underline, .primary-menu a.active .underline {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffe8a8;
}
.primary-menu a .wrap {
  position: relative;
}
.primary-menu a .wrap .animated-underline {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ffe8a8;
}

.stage-info {
  position: relative;
  z-index: 1;
}

.stage-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  mask-image: linear-gradient(to left, rgb(0, 0, 0) 30%, transparent 100%);
  background-size: cover;
  background-position: bottom;
}

.film-tile > div {
  border-width: 2px;
  border-style: solid;
  border-color: #000000;
  transition: all 0.5s;
}

.film-tile.active > div,
.film-tile:hover > div {
  border-color: #f8cf40;
}

.film-tile-title {
  transition: all 0.2s;
  width: 100%;
}

.header {
  transition: background-color 0.3s;
}

.header.scrolled {
  background-color: #121212;
}

.mainSection .carousel-root,
.mainSection .carousel-root .carousel.carousel-slider,
.mainSection .carousel-root .carousel .slider-wrapper,
.mainSection .carousel-root .carousel .slider,
.mainSection .carousel-root .carousel .slide {
  height: inherit;
}

.carousel .slide.selected {
  z-index: 1 !important;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  border-top: 30px solid transparent !important;
  border-bottom: 30px solid transparent !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 30px solid #fff !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 30px solid #fff !important;
}

.primary-menu a {
  padding: 0;
}

.primary-menu a:hover,
.primary-menu a.active {
  background: none;
}

.primary-menu a:hover .underline,
.primary-menu a.active .underline {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffe8a8;
}

.primary-menu a .wrap {
  position: relative;
}

.primary-menu a .wrap .animated-underline {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ffe8a8;
}

.videoWrapper video {
  max-width: 80%;
  background: black;
  margin: auto;
  padding: 25px 0;
}

.carousel .control-dots {
  z-index: 10;
}
.carousel .slider-wrapper.axis-horizontal .slider {
  transform: none !important;
}
